import { css } from '@emotion/react'
import { graphql } from 'gatsby'
import React from 'react'

import Layout from '../components/Layout'
import LightboxContent from '../components/LightboxContent'
import { baseGrid } from '../theme/mixins'

export const data = graphql`
  query ($slug: String!) {
    lightbox: datoCmsLightbox(slug: { eq: $slug }) {
      ...LightboxFragment
    }
  }
`
const mainStyle = css`
  ${baseGrid}
`
const contentStyle = css`
  grid-column: 2 / -2;
  max-width: calc(72ch + 2 * var(--gutter-mlg));
  width: 100%;
  background-color: white;
  padding: 2rem var(--gutter-mlg) 4rem;
  margin: var(--gutter-xlg) 0;
  align-self: center;
  justify-self: center;
  box-sizing: border-box;
`

const LightboxPage = ({ data }) => {
  return (
    <Layout mainCss={mainStyle}>
      <LightboxContent css={contentStyle} data={data.lightbox} />
    </Layout>
  )
}

export default LightboxPage
